<template>
  <v-container v-if="hasPermission" fluid>
    <mex-sperm-spinner v-if="systemUsersLoading" spinnerText="Loading System Users" />
    <template v-else>
      <mex-heading content="System Users Overview" />
      <v-row justify="center">
        <mex-card icon="mdi-account-group">
          <v-expansion-panels>
            <v-expansion-panel>
              <v-expansion-panel-header>FILTER</v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row justify="center">
                  <v-col cols="12">
                    <v-select
                      v-model="roleFilter"
                      :items="roles"
                      dense
                      hide-details
                      item-text="name"
                      item-value="SystemRoleID"
                      label="Roles"
                      multiple
                      outlined
                    >
                      <template v-slot:selection="data">
                        <v-chip class="ma-1">
                          <v-icon class="mr-1" x-small>mdi-account-multiple</v-icon>
                          {{ data.item.name }}
                        </v-chip>
                      </template>
                    </v-select>
                  </v-col>
                </v-row>
                <v-row justify="center">
                  <v-col cols="auto">
                    <mex-btn content="Set filter" icon="mdi-filter" @click="setFilter" />
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <v-row justify="end">
            <mex-btn
              v-if="writeUsers"
              class="mr-10 mt-10"
              content="REGISTER NEW USER"
              icon="mdi-account-plus"
              @click="goToRegisterNewUser"
            />
          </v-row>
          <v-row>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              class="ml-10 mr-10"
              hide-details
              label="Search"
              single-line
            ></v-text-field>
          </v-row>
          <v-row>
            <v-col>
              <v-divider class="mb-1 mt-3" />
            </v-col>
          </v-row>
          <v-data-table
            :headers="getTableHeaders"
            :items="users"
            :search="search"
            item-key="SystemUserID"
            sort-by="UserID"
            :footer-props="{
              'items-per-page-options': getTablePagination.rowsPerPage,
            }"
            :items-per-page="getTablePagination.defaultRowsPerPage"
          >
            <template v-slot:item.active="{ item }">
              <mex-status-lamp v-if="item.active && !item.registrationTimeStamp" color="success" />
              <mex-status-lamp v-else-if="!item.active" color="error" />
              <mex-status-lamp v-else blink color="warning" />
            </template>
            <template v-slot:item.actions="{ item }">
              <mex-btn
                icon="mdi-circle-edit-outline"
                iconOnly
                @click="goToEditUser(item.SystemUserID)"
              />
              <mex-btn
                v-if="writeUsers"
                icon="mdi-delete-outline"
                iconOnly
                @click="deleteUser(item)"
              />
            </template>
            <template v-slot:item.assignedOrganizations="{ item }">
              <v-chip v-if="item.Organizations.length > 0">{{ item.Organizations[0].name }}</v-chip>
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-chip v-if="item.Organizations.length > 1" v-bind="attrs" v-on="on">
                    +{{ item.Organizations.length - 1 }}
                  </v-chip>
                </template>
                <v-chip
                  v-for="organization in item.Organizations"
                  :key="organization.OrganizationID"
                >
                  {{ organization.name }}
                </v-chip>
              </v-tooltip>
            </template>
            <template v-slot:item.registrationTimeStamp="{ item }">
              <v-icon v-if="!registrationValid(item.registrationTimeStamp)" color="error">
                mdi-lock-clock
              </v-icon>
              <v-icon v-else-if="item.registrationTimeStamp" color="warning">
                mdi-help-rhombus-outline
              </v-icon>
              <v-icon v-else color="success">mdi-check</v-icon>
            </template>
            <template v-slot:item.roles="{ item }">
              <v-chip
                v-for="(role, index) in item.SystemRoles"
                :key="index"
                :disabled="!readRoles"
                class="ml-1 mr-1"
                @click="goToEditRole(role.SystemRoleID)"
              >
                {{ role.name }}
              </v-chip>
            </template>
            <template v-slot:item.createdAt="{ item }">
              {{ new Date(item.createdAt).toLocaleDateString() }} |
              {{ new Date(item.createdAt).toLocaleTimeString() }}
            </template>
            <template v-slot:item.forceLogout="{ item }">
              <mex-btn
                icon="mdi-lock-alert-outline"
                iconOnly
                tooltip="force logout system user"
                @click="forceLogoutSystemUser(item.SystemUserID)"
              />
            </template>
          </v-data-table>
          <v-row justify="end">
            <v-col cols="auto">
              <v-checkbox v-model="hideRegCompleteColumn" label="Hide Reg. Complete" small />
            </v-col>
          </v-row>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5">
                Are you sure you want to delete this user?
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <mex-btn content="Cancel" text @click="closeDelete"></mex-btn>
                <mex-btn content="Delete" text @click="deleteUserConfirm"></mex-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </mex-card>
      </v-row>
    </template>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import SystemUsersService from "../../services/systemUsers.service";
import SystemRolesService from "../../services/systemRoles.service";
import requiredPermissions from "../../requiredPermissions";
import tablePagination from '../../config/tablePagination.config';

export default {
  name: "UsersOverview.vue",
  computed: {
    ...mapGetters("sysAuth", ["isLoggedIn", "getUser", "getUserPermissions"]),
    writeUsers() {
      return this.getUserPermissions.includes("write_System_User Role Administration_User");
    },
    readRoles() {
      return this.getUserPermissions.includes("read_System_User Role Administration_Role");
    },
    getTableHeaders() {
      if (this.hideRegCompleteColumn) {
        return this.systemUserTableHeaders.filter(x => {
          return x.value !== "registrationTimeStamp" && x.value !== "createdAt";
        });
      }
      return this.systemUserTableHeaders;
    },
    getTablePagination() {
      return tablePagination;
    },
  },
  data() {
    return {
      users: [],
      search: "",
      dialogDelete: false,
      toDeleteUser: null,
      hasPermission: false,
      roleFilter: [],
      roles: [],
      hideRegCompleteColumn: true,
      systemUsersLoading: false,
      systemUserTableHeaders: [
        { text: "UserID", align: "start", value: "SystemUserID" },
        { text: "Username", align: "start", value: "username" },
        { text: "First Name", align: "start", value: "firstName" },
        { text: "Last Name", align: "start", value: "lastName" },
        { text: "E-Mail", align: "start", value: "email" },
        { text: "Active", align: "center", value: "active" },
        { text: "Roles", align: "center", value: "roles", sortable: false },
        {
          text: "Assigned Organizations",
          align: "center",
          value: "assignedOrganizations",
          sortable: false
        },
        { text: "Reg. completed", align: "center", value: "registrationTimeStamp" },
        { text: "Created At", align: "center", value: "createdAt" },
        { text: "Actions", align: "center", value: "actions", sortable: false },
        { text: "Force Logout", align: "center", value: "forceLogout", sortable: false }
      ]
    };
  },
  methods: {
    fetchUsers() {
      SystemUsersService.getUsersFiltered(this.roleFilter)
        .then(response => {
          this.users = response.data.users;
          this.systemUsersLoading = false;
        })
        .catch(err => {
          this.systemUsersLoading = false;
          this.$toast.error(err.response.data);
        });
    },
    fetchRoles() {
      SystemRolesService.getRolesList().then(response => {
        this.roles = response.data.roles;
      });
    },
    setFilter() {
      this.fetchUsers();
    },
    goToEditUser(systemUserID) {
      this.$router.push({ name: "UserView", params: { id: systemUserID } });
    },
    goToEditRole(systemRoleID) {
      this.$router.push({ name: "RoleView", params: { id: systemRoleID } });
    },
    goToRegisterNewUser() {
      this.$router.push({ name: "RegisterUser" });
    },
    deleteUser(item) {
      this.toDeleteUser = item;
      this.dialogDelete = true;
    },
    deleteUserConfirm() {
      SystemUsersService.deleteUser(this.toDeleteUser.SystemUserID)
        .then(() => {
          this.$toast.success("Deleted successfully");
          this.$router.push({ name: "ParamsReload", params: { name: "UsersOverview" } });
        })
        .catch(err => {
          this.$toast.error(`Error while deleting: ${err.response.data.message}`);
          this.closeDelete();
        });
    },
    closeDelete() {
      this.dialogDelete = false;
    },
    registrationValid(registrationTimeStamp) {
      if (registrationTimeStamp) {
        const actTimeStamp = !Date.now ? new Date().getTime() : Date.now();
        const timeSpanMS = actTimeStamp - registrationTimeStamp;
        const timeSpanMin = Math.floor(timeSpanMS / (1000 * 60));
        if (timeSpanMin > 10) {
          return false;
        }
      }
      return true;
    },
    forceLogoutSystemUser(systemUserID) {
      SystemUsersService.systemUserForceLogout(systemUserID)
        .then(() => {
          this.$toast.success(`System User ID${systemUserID} logged out`);
        })
        .catch(err => {
          this.$toast.error(err.repsonse.body);
        });
    }
  },
  created() {
    this.systemUserLoading = true;
    this.$userPermissions.fetchCurrentUserPermissions(requiredPermissions.userOverview, this.$store)
      .then((hasPermission) => {
        if (hasPermission) {
          this.hasPermission = true;
          if (this.$route.params.roleID) {
            this.roleFilter.push(parseInt(this.$route.params.roleID, 10));
          }
          this.fetchUsers();
          this.fetchRoles();
        } else {
          this.$router.push({ name: "NotFound" });
        }
      })
      .catch(() => {
        this.$router.push({ name: "NotFound" });
      })
  }
};
</script>

<style scoped></style>
